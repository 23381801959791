'use strict';
import $ from 'jquery';
import 'slick-carousel';
import 'magnific-Popup';

export default class Slider {
  constructor() {
    this.name = 'slider';
    // console.log('%s module', this.name.toLowerCase());
	if ($('#anothertrailertypes').length){
	  $('#anothertrailertypes').slick({
			infinite: true,
		    slidesToShow: 3,
		    slidesToScroll: 3,
			prevArrow: '<a class="slick-prev"></a>',
			nextArrow: '<a class="slick-next"></a>',
			arrows: true,
			dots: false,
	  });
	}
    if ($('#js-slider').length){
      $('#js-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"></a>',
        nextArrow: '<a class="slick-next"></a>',
        vertical: true,
        arrows: true,
        dots: false
      });
    }
    if ($('#js-item-slider').length){
      $('.fancybox').magnificPopup({type:'image'});
      $('#js-item-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"></a>',
        nextArrow: '<a class="slick-next"></a>',
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: '#js-item-slider-nav'
      });
      $('#js-item-slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '#js-item-slider',
        dots: false,
        centerMode: true,
        arrows: false,
        focusOnSelect: true
      });
    }
  }
}