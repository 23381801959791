'use strict';
import $ from 'jquery';

export default class Reviews {
  constructor() {
    this.name = 'reviews';
    // console.log('%s module', this.name.toLowerCase());
    if ($('#js_reviews_slider').length){
      $('#js_reviews_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"></a>',
        nextArrow: '<a class="slick-next"></a>',
        arrows: false,
        dots: true
      });
    }
  }
}