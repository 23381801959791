'use strict';

import $ from 'jquery';
import 'remodal';


export default class Modal {
  constructor() {
    this.name = 'modal';
    // console.log('%s module', this.name.toLowerCase());
    $('.checkbox').click(function(){
    // console.log('click');
    var checkBoxes = $(this).find('input');
    checkBoxes.attr("checked", !checkBoxes.attr("checked"));
    });
  }
}