// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';


import General from './general';
import Header from '../_modules/header/header';
import Slider from '../_modules/slider/slider';
import Calc from '../_modules/calc/calc';
import Reviews from '../_modules/reviews/reviews';
import Modal from '../_modules/modal/modal';

$(() => {
  // console.log('Welcome to Yeogurt!');
  new General();
  new Header();
  new Slider();
  new Calc();
  new Reviews();
  new Modal();
});
