'use strict';

import $ from 'jquery';
import Inputmask from 'inputmask';

export default class General{
  constructor() {
    this.name = 'general';
    // console.log('%s module', this.name.toLowerCase());
    if ($('.callback__input').length){
      Inputmask({'showMaskOnFocus': false, 'mask': '+7 (999) 999-99-99'}).mask($('.callback__input'));
    }
    if ($('.feedback__input').length){
      Inputmask({'showMaskOnFocus': false, 'mask': '+7 (999) 999-99-99'}).mask($('.feedback__input'));
    }
    if ($('.mask').length){
      Inputmask({'showMaskOnFocus': false, 'mask': '+7 (999) 999-99-99'}).mask($('.mask'));
    }
  }
  
}