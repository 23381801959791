'use strict';
import $ from 'jquery';

export default class Header {
  constructor() {
    this.name = 'header';
    // console.log('%s module', this.name.toLowerCase());
    var menu=$('.menu-semitrailer');
    // $('.topmenu .first').append(menu);
    $('.topmenu .deeper').mouseover(function(){
        var menu=$('.menu-semitrailer');
        var w=$(window).width();
        if(w>468){
          menu.toggle();
          menu.css('display', 'flex');
          $('.deeper').addClass('open');
          }
        });
    $('.deeper').mouseout(function(){
      var menu=$('.menu-semitrailer');
      menu.toggle();
      $('.deeper').removeClass('open');
    });
  }
}
