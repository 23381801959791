'use strict';
import $ from 'jquery';

export default class Calc {
  constructor() {
    this.name = 'calc';
    // console.log('%s module', this.name.toLowerCase());
    // Настройки //
    //<?php echo $round_eur;?>*20000;
    // var Sum = 1; // Шаг суммы займа
    // var MinSum = <?php echo $round_eur;?>*20000; // Минимальная   сумма займа
    // var MaxSum = <?php echo $round_eur;?>*70000; // Максимальная  сумма займа
    // var Month = 1; // Шаг месяца
    // var MinMonth = 12; // Минимальный  срок кредита
    // var MaxMonth = 36; // Максимальный срок  кредита
    // var Procent = 1; // Шаг процентов
    // var MinProcent = 5; // Минимальный  процент
    // var MaxProcent = 49; // Максимальный процент
    // // Настройки конец //

    // // телефон
    // var cleave = new Cleave('.mask', {
    //     phone: true,
    //     phoneRegionCode: 'ru'
    // });

    // var thousandSeparator = function(str) {
    //     var parts = (str + '').split('.'),
    //         main = parts[0],
    //         len = main.length,
    //         output = '',
    //         i = len - 1;

    //     while(i >= 0) {
    //         output = main.charAt(i) + output;
    //         if ((len - i) % 3 === 0 && i > 0) {
    //             output = ' ' + output;
    //         }
    //         --i;
    //     }

    //     if (parts.length > 1) {
    //         output += '.' + parts[1];
    //     }
    //     return output;
    // };

    // // неиспользуем
    // function calc_finance_cn(e){
    //     var a=e.value;
    //     isNaN(a)&&(a=a.substring(0,a.length-1),e.value=a)
    // }
    // function lising(lpr,mon,msum) {
    //     //lpr (проценты),mon(месяцы),msum(сумма)

    //     var p1 = Number(msum)-(Number(msum)*20/120);
    //     // 35 на калк.
    //     var p2 = p1/100*lpr;
    //     //alert(p2);
    //     var p3 = Number(p1)-Number(p2);
    //     //19 константа;
    //     var p4 = p3/100*19;
    //     //alert(p4)
    //     var p5 = Number(p3)+Number(p4);
    //     //alert(p5)
    //     var p6 = p5 * 1.2;
    //     //alert(p6);
    //     // 24 месяц на кальк.
    //     var result_p7 = Math.round(p6 / mon);
    //     // alert(p7);
    //     //Math.round(p7);
    //     //(2280000-(2280000*20/120)-35%+19%)*1,20
    //     return result_p7;
    // }


    // // первоначальное заполнение
    // document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(MinProcent,MinMonth,MinSum) );

    // // запрет
    // document.getElementById("js-display-decimal").onkeydown = function(e){
    //     if((e.which >=48 && e.which <=57)  // цифры
    //         || (e.which >=96 && e.which <=105)  // num lock
    //         || e.which==8 // backspace
    //         || (e.which >=37 && e.which <=40) // стрелки
    //         || e.which==46 // delete
    //         || e.which==32) // delete
    //     {

    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    // // пробелы при уводе и новый расчет
    // $("#js-display-decimal").blur(function() {
    //     var Rub =  $(this).val();
    //     Rub = Number(Rub.replace(/\s+/g, ''));

    //     if(Rub < MinSum) {Rub = MinSum;  $(this).val(thousandSeparator(MinSum));}
    //     if(Rub > MaxSum) {Rub = MaxSum;  $(this).val(thousandSeparator(MaxSum));}

    //     $(this).val(thousandSeparator(Rub));

    //     var Month = Number($("#js-display-decimal2").val()); // месяцы
    //     var ProcentCalc = Number($("#js-display-decimal2_2").val()); // проценты
    //     document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(ProcentCalc,Month,Rub) );

    // });

    // // печатаем и считаем
    // $('#js-display-decimal').keyup(function() {

    //     // получим калькулятор
    //     var Rub = $(this).val();
    //     Rub = Number(Rub.replace(/\s+/g, '')); // удалим пробелы
    //     $("#slider-range").slider('value', Rub);

    //     var Month = Number($("#js-display-decimal2").val()); // месяцы
    //     var ProcentCalc = Number($("#js-display-decimal2_2").val()); // проценты
    //     document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(ProcentCalc,Month,Rub) );

    // });


    // ////////////////////////////////////////////////////
    // $("#slider-range").slider({
    //     range: "min",
    //     min: MinSum,
    //     max: MaxSum,
    //     value: MinSum,
    //     step: Sum,
    //     slide: function(event, ui) {
    //         $("#js-display-decimal").val(thousandSeparator(ui.value)); // рубли
    //         var Month = Number($("#js-display-decimal2").val()); // месяцы
    //         var ProcentCalc = Number($("#js-display-decimal2_2").val()); // проценты
    //         document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(ProcentCalc,Month,ui.value) );
    //     }
    // });

    // $("#slider-range2").slider({
    //     range: "min",
    //     orientation: "horizontal",
    //     min: MinMonth,
    //     max: MaxMonth,
    //     step: Month,
    //     animate: true,
    //     value: MinMonth,
    //     slide: function(event, ui) {
    //         $("#js-display-decimal2").val(ui.value);
    //         var Rub= $("#js-display-decimal").val();
    //         Rub = Number(Rub.replace(/\s+/g, '')); // удалим пробелы
    //         var ProcentCalc = Number($("#js-display-decimal2_2").val()); // проценты
    //         document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(ProcentCalc,ui.value,Rub) );


    //     }
    // });

    // $("#slider-range2_2").slider({
    //     range: "min",
    //     orientation: "horizontal",
    //     min: MinProcent,
    //     max: MaxProcent,
    //     step: Procent,
    //     animate: true,
    //     value: MinProcent,
    //     slide: function(event, ui) {
    //         $("#js-display-decimal2_2").val(ui.value);
    //         var Rub= $("#js-display-decimal").val();
    //         Rub = Number(Rub.replace(/\s+/g, '')); // удалим пробелы
    //         var Month = Number($("#js-display-decimal2").val()); // месяцы
    //         document.getElementById('js-display-result').innerHTML = thousandSeparator( lising(ui.value,Month,Rub) );

    //     }
    // });

    // document.getElementById('js-display-decimal').value = thousandSeparator(MinSum);
    // document.getElementById('js-display-decimal2').value = MinMonth;
    // document.getElementById('js-display-decimal2_2').value = MinProcent;


    // //

    // function validate_form() {
    //     valid = true;
    //     if ( document.CalcForm.phone.value == "" )  {
    //         alert ( "Пожалуйста Введите  Телефон" );
    //         valid = false;
    //         return valid;
    //     }///
    //     if ( document.CalcForm.checkboxYes.checked == false )  {
    //         alert ( "Согласие на обработку персональных данных" );
    //         valid = false;
    //         return valid;
    //     }///

    //     if(valid) {
    //         return true;
    //     }
    // }
  }
}
